import React, { Component, createRef } from "react";
import GetUserToken from "../component/getToken";
import axios from "axios";
import Breadcrumb from "../component/Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";

// material ui
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import TelegramLink from "../component/TelegramLink";
import { baseUrl } from "../assets/baseUrl";
import { baseUrlTest } from "../assets/baseUrl";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

class FileOfLecture extends Component {
  state = {
    files: [],
    openModal: false,
    fileData: "",
    pdfUrl: "",
    pdfData: "",
    pdfRef: null,
    currentPage: 1,
    heightOfPage: window.innerHeight,
    WidthOfPage: window.innerWidth,
    isTablet: false,
    isMobile: false,
  };

  // iframeRef = React.createRef();
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
  }

  componentDidMount() {
    // const isAndroid = /Android/i.test(navigator.userAgent);
    const isTablet =
      /Tablet|iPad/i.test(navigator.userAgent) &&
      !/Mobile/i.test(navigator.userAgent);
    const isMobile = /Android|iPhone|Mobile/i.test(navigator.userAgent);

    console.log("isTablet", isTablet);
    console.log("isMobile", isMobile);
    this.setState({ isMobile, isTablet });

    const token = GetUserToken();
    const subjectId = this.props.match.params.subid;
    const montId = this.props.match.params.motid;
    axios
      .get(
        // `http://hossam1234-001-site1.ftempurl.com/Student/lectures/${subjectId}?Month=${montId}`,
        `${baseUrlTest}api/FileMangment/GetBySubject?SubjectID=${subjectId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log("res", res);

        this.setState({
          files: res.data.items,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  disableRightClick = () => {
    const iframe = this.iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.document.addEventListener("contextmenu", (e) =>
        e.preventDefault()
      );
    }
  };

  createBlobUrl(base64) {
    // إزالة الجزء الخاص بالـ header في الـ Base64
    const byteString = atob(base64.split(",")[1]);
    const byteNumbers = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  }

  handleContextMenu = (e) => {
    e.preventDefault();
    console.log("Right Click");
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    console.log("window inner height: ", this.state.heightOfPage);
    console.log("window inner width: ", this.state.WidthOfPage);

    const { files } = this.state;
    const filePath = `${process.env.PUBLIC_URL}/assets/confirmation.pdf`;
    const { pdfData, numPages, pageNumber } = this.state;
    const pdfUrl2 = `data:application/pdf;base64,${pdfData}`;

    const { pdfUrl, WidthOfPage } = this.state;

    // if (!pdfUrl) return <div>Loading PDF...</div>;
    const openFile = (id, index) => {
      const token = GetUserToken();
      console.log("id", id);
      axios
        .get(`${baseUrlTest}api/FileMangment/show?fileID=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })

        .then((res) => {
          console.log(res);
          console.log("this.state.innerWidth", this.state.WidthOfPage);

          this.setState({ fileData: res });
          console.log("filedata", res);
          const base64Pdf = res.data.fileData;
          this.setState({ pdfData: base64Pdf });
          const pdfUrl = this.createBlobUrl(base64Pdf);
          this.setState({ pdfUrl });
          if (this.state.isMobile || this.state.isTablet) {
            const link = document.createElement("a");
            link.href = res.data.fileData;
            link.download = res.data.name + ".pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(res.data.fileData);
          } else {
            this.setState({ openModal: true });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
      // this.setState({ openModal: true });
    };

    const style = {
      // position: "absolute",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      // // width: '90%',
      bgcolor: "background.paper",
      // border: "2px solid #000",
      // boxShadow: 24,
      // padding: '32px'
    };

    const handleClose = () => {
      this.setState({ openModal: false, pdfUrl: null });
    };
    return (
      <div>
        <div
          className="breadcrumb"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/levelsLogo.jpg')`,
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {" "}
          <h5>الملفات</h5>{" "}
        </div>
        <div className="month-page lectures-page">
          <Container>
            <h5 className="list-header">اختر الملف</h5>
            <Grid container spacing={3}>
              {files.map((le, index) => (
                <>
                  {/* {le.isAppear ? ( */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={index}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      id={"filelink" + index}
                      onClick={() => openFile(le.id, index)}
                      // to={`/lecture-details/${le.id}`}
                      className="month-card"
                      key={index}
                    >
                      {" "}
                      {le.name}{" "}
                    </div>
                  </Grid>
                  {/* ) : null} */}
                </>
              ))}
            </Grid>

            {/* <iframe id="your-iframe-id" src={filePath} width="640" height="360"></iframe>  */}
          </Container>
          <TelegramLink />
        </div>

        {/* <div>
          <canvas ref={this.canvasRef}></canvas>
          <div>
            <button onClick={this.prevPage}>Previous</button>
            <button onClick={this.nextPage}>Next</button>
          </div>
        </div> */}

        <Modal
          open={this.state.openModal}
          onClose={this.onCloseModal}
          fullscreen="true"
        >
          <Box sx={style}>
            {/* <Typography style={{padding:"30px", display:'none'}} id="modal-modal-title" variant="h6" component="h2">
                    هل انت متأكد من طلب أعاده تعين الحساب 
                    </Typography>   */}
            {/* <p style={{color:'gray'}}>علماً بأن هذا سوف يترتب عليه تسجيل خروج الحساب من الاجهزه الاخره</p> */}
            {pdfUrl ? (
              <div>
                <div
                  style={{
                    width: "150px",
                    height: "30px",
                    position: "fixed",
                    top: "12px",
                    zIndex: "99999 !important",
                    background: "#323639",
                  }}
                ></div>
                {/* {WidthOfPage > 1200 ? ( */}
                <div
                  onContextMenu={this.handleContextMenu}
                  style={{
                    width: "100%",
                    height: this.state.heightOfPage,
                    position: "fixed",
                    background: "transparent",
                    right: "16px",
                  }}
                ></div>
                {/* ) : null} */}
                <iframe
                  id="your-iframe-id"
                  src={pdfUrl}
                  width="100%"
                  height={this.state.heightOfPage - 50}
                  allowfullscreen
                ></iframe>
                {/* <embed src={pdfUrl} width="100%" height="500px" type="application/pdf"></embed> */}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}

            <div style={{ textAlign: "left" }}>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ color: "green", padding: "0 30px" }}
              >
                اغلاق
              </Button>
            </div>
          </Box>
        </Modal>
        <Footer />
      </div>
    );
  }
}

export default FileOfLecture;
