// export const baseUrl = "https://localhost:44334/";
// export const baseUrl = "http://shandoo-001-site1.ctempurl.com/";

// // export const baseUrl = "https://api.golearn.site/";
// export const baseUrl = "https://restfulapi.golearn.site/";
export const baseUrl =
  "https://dazzling-wozniak.69-10-32-26.plesk.page/";
export const baseUrlTest = "https://dazzling-wozniak.69-10-32-26.plesk.page/";
// export const baseUrlTest = "https://backend.drmohamedabdelrahman-anatomy.com/";

// export const baseUrl = "http://ddd.somee.com/";
