import React, { Component } from "react";
import GetUserToken from "../component/getToken";
import axios from "axios";
import Breadcrumb from "../component/Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";

// material ui
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import TelegramLink from "../component/TelegramLink";
import { baseUrl } from "../assets/baseUrl";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

export default class LectureDetails extends Component {
  state = {
    lectureDetails: {},
    subjectDetails: {},
    loading: false,
    loading1: false,
    lectureMessage: "",
    fileeData: null,
    heightOfPage: window.innerHeight,
    WidthOfPage: window.innerWidth,
    isTablet: false,
    isMobile: false,
    fileData: "",
    openModal: false,
  };

  async componentDidMount() {
    const isTablet =
      /Tablet|iPad/i.test(navigator.userAgent) &&
      !/Mobile/i.test(navigator.userAgent);
    const isMobile = /Android|iPhone|Mobile/i.test(navigator.userAgent);

    console.log("isTablet", isTablet);
    console.log("isMobile", isMobile);
    this.setState({ isMobile, isTablet });

    const lectureId = this.props.match.params.id;
    const token = GetUserToken();
    this.setState({ loading: true, loading1: true });
    await axios
      .get(`${baseUrl}api/StudyLectures/Files/` + lectureId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        this.setState({ fileeData: res, loading1: false });
      })
      .catch((errors) => {
        console.log(errors);
      });
    await axios
      .get(
        // "http://hossam1234-001-site1.ftempurl.com/api/Lectures/" + lectureId,
        `${baseUrl}api/Lectures/` + lectureId,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          lectureDetails: res.data,
          subjectDetails: res.data.subject,
          lectureMessage: res.data.description,
        });
      })
      .catch((errors) => {
        if (
          errors.response &&
          errors.response.status &&
          errors.response.status == "401"
        ) {
          toast.error("تاكد من تسجيل الدخول");
        }
        if (
          (errors.response &&
            errors.response.data === "Watching limit exceeded") ||
          errors.response.data.title == "Not Found"
        ) {
          swal
            .fire({
              title: "لا يمكنك مشاهدة المحاضرة مرة اخرى!",
              icon: "error",
              confirmButtonColor: "#1fb354",
              cancelButtonColor: "#555",
              confirmButtonText: "طلب فتح المحاضرة",
              showCancelButton: false,
              showConfirmButton: true,
            })
            .then((result) => {
              let reOpenLecture = { LectureID: lectureId, Reason: "-" };
              if (result.isConfirmed) {
                axios
                  .post(
                    `${baseUrl}api/StudyLectures/Request_Open_Watching`,
                    reOpenLecture,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                  .then((res) => {
                    swal.fire("تم ارسال طلبك", "", "success");
                  });
              }
            });
        }
        console.log(errors.response);
      });
  }
  createBlobUrl(base64) {
    // إزالة الجزء الخاص بالـ header في الـ Base64
    const byteString = atob(base64.split(",")[1]);
    const byteNumbers = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  }

  render() {
    const openFile = (file) => {
      const token = GetUserToken();

      console.log("this.state.innerWidth", this.state.WidthOfPage);
      const base64Pdf = file;
      this.setState({ pdfData: base64Pdf });
      const pdfUrl = this.createBlobUrl(base64Pdf);
      this.setState({ pdfUrl });
      this.setState({ openModal: true });

      // this.setState({ openModal: true });
    };
    const style = {
      bgcolor: "background.paper",
    };

    const handleClose = () => {
      this.setState({ openModal: false, pdfUrl: null });
    };
    const {
      lectureDetails,
      subjectDetails,
      lectureMessage,
      fileeData,
      loading,
      loading1,
      heightOfPage,
      WidthOfPage,
      isTablet,
      isMobile,
      pdfUrl,
    } = this.state;

    return (
      <div>
        <div
          className="breadcrumb"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/levelsLogo.jpg')`,
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {" "}
          <h5>تفاصيل المحاضره</h5>{" "}
        </div>
        <div className="lectures-details subject-details-page">
          {loading && loading1 ? (
            <div className="loading">
              <button class="btn">
                <span class="spinner"></span>
                <span class="btn__text">برجاء الانتظار...</span>
              </button>
            </div>
          ) : null}
          <Container>
            {lectureMessage == "" ? (
              <h5 className="list-header">{lectureMessage}</h5>
            ) : (
              <>
                <h5 className="list-header">{lectureDetails.name}</h5>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={6}>
                    <h5 className="box-title">فيديو المحاضره</h5>
                    <div style={{ paddingTop: "56.25%", position: "relative" }}>
                      <iframe
                        src={lectureDetails.lectureLink}
                        style={{
                          // border: "0",
                          maxWidth: "100%",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          height: "100%",
                          width: "100%",
                        }}
                        allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={6}>
                    <h5 className="box-title">تفاصيل المحاضره</h5>
                    <ul>
                      <li>
                        <span>الاسم</span>{" "}
                        <span className="price"> {lectureDetails.name} </span>{" "}
                      </li>
                      <li>
                        <span>الماده التابعه لها</span>{" "}
                        <span> {subjectDetails.name} </span>{" "}
                      </li>
                      <li>
                        <span>الشهر التابعه لها</span>{" "}
                        <span> {lectureDetails.month} </span>{" "}
                      </li>
                      <li>
                        <span>الحاله</span>{" "}
                        <span>
                          {" "}
                          {lectureDetails.isFree ? "مجانيه" : "مدفوعه"}{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <span>ملف المحاضرة</span>{" "}
                        <span>
                          {isTablet || isMobile ? (
                            <a
                              download={fileeData.data.name}
                              href={fileeData.data.fileData}
                            >
                              {lectureDetails.fileName}
                            </a>
                          ) : (
                            <p
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => openFile(fileeData.data.fileData)}
                            >
                              {lectureDetails.fileName}
                            </p>
                          )}
                        </span>{" "}
                      </li>
                      <li>
                        <span>الدخول للامتحان</span>{" "}
                        <Link to={`/exam/${lectureDetails.examID}`}>
                          <i className="fas fa-arrow-left"></i>
                        </Link>{" "}
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
          <TelegramLink />
        </div>
        <Modal
          open={this.state.openModal}
          onClose={this.onCloseModal}
          fullscreen="true"
        >
          <Box sx={style}>
            {pdfUrl ? (
              <div>
                <div
                  style={{
                    width: "150px",
                    height: "30px",
                    position: "fixed",
                    top: "12px",
                    zIndex: "99999 !important",
                    background: "#323639",
                  }}
                ></div>
                <div
                  onContextMenu={this.handleContextMenu}
                  style={{
                    width: "100%",
                    height: this.state.heightOfPage,
                    position: "fixed",
                    background: "transparent",
                    right: "16px",
                  }}
                ></div>
                <iframe
                  id="your-iframe-id"
                  src={pdfUrl}
                  width="100%"
                  height={this.state.heightOfPage - 50}
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            )}

            <div style={{ textAlign: "left" }}>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ color: "green", padding: "0 30px" }}
              >
                اغلاق
              </Button>
            </div>
          </Box>
        </Modal>
        <Footer />
      </div>
    );
  }
}
